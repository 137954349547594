<template>
  <layout-base>
    <template slot="main-layout">
      <div class="services">
        <section
          class="section_services_header"
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-delay="500"
        >
          <div class="container">
            <h2>Serviços</h2>
            <p>
              A AMMAX é uma agência dedicada aos resultados em todos os níveis.
              E estamos onde o seu cliente está. Se o consumidor de hoje é
              omnichannel e a sua marca também precisa ser.
            </p>
          </div>
        </section>

        <section class="sections_services sections_services__one">
          <div class="container">
            <box-two
              themeType="inverted"
              title="Marketing de Performance"
              :image="imageBoxSeven"
              textOne="Para uma boa performance é necessário o engajamento de todos os agentes da jornada de venda. Da captação do lead, passando pela  integração da força de vendas até assinatura do cliente. A AMMAX  cuida de tudo isso para melhorar os resultados em todas as fases da  convivência com o cliente e seu relacionamento com a força de venda."
            />
          </div>
        </section>

        <section class="sections_services sections_services__two">
          <div class="container">
            <box-two
              themeType="default"
              title="On + Off"
              :image="imageBoxOne"
              textOne="Criamos estratégias de comunicação para marcas que exigem resultados em todos os canais digitais e off-line."
            />
          </div>
        </section>
        <section class="sections_services sections_services__one">
          <div class="container">
            <box-two
              themeType="inverted"
              title="Promo"
              :image="imageBoxTwo"
              textOne="Viabilizamos as ações promocionais que chamam a atenção para a sua marca e levam a uma experiência positiva e ainda mais próxima com o seu consumidor."
            />
          </div>
        </section>

        <section class="sections_services sections_services__two">
          <div class="container">
            <box-two
              themeType="default"
              title="Eventos"
              :image="imageBoxThree"
              textOne="Temos grande experiência na organização e execução de eventos corporativos e promocionais. A cada ação, pensamos na sua marca de forma ampla e com foco em resultados."
            />
          </div>
        </section>

        <section class="sections_services sections_services__one">
          <div class="container">
            <box-two
              themeType="inverted"
              title="Sites, portais e blogs"
              :image="imageBoxFour"
              textOne="Desenvolvemos sites, portais e blogs responsivos focados na experiência do usuário e otimizados para gerar negócios e resultados em visitas, conversões e vendas."
            />
          </div>
        </section>

        <section class="sections_services sections_services__two">
          <div class="container">
            <box-two
              themeType="default"
              title="Planejamentos"
              :image="imageBoxFive"
              textOne="A partir de um diagnóstico profundo do seu negócio, desenvolvemos o planejamento estratégico sob medida."
            />
          </div>
        </section>

        <section class="sections_services sections_services__one">
          <div class="container">
            <box-two
              themeType="inverted"
              title="Projetos especiais"
              :image="imageBoxSix"
              textOne="Você precisa colocar uma ideia em prática? Precisa de um projeto que ninguém ainda fez? Projetos especiais são com a gente mesmo."
              textTwo=" Campanhas de venda, promocionais, ativações diferenciadas, aplicativos, endomarketing conquista de novos clientes... estamos às ordens!"
            />
          </div>
        </section>

        <section class="sections_services sections_services__four">
          <div class="container">
            <box-two
              themeType="inverted"
              title="Imob de Bolso"
              :image="imageBoxEight"
              textOne="Conheça nossa ferramenta facilitadora e com melhor custo-benefício para a divulgação e negociação de imóveis."
            >
              <template slot="infos-slot">
                <div class="buttons_store">
                  <a
                    href="https://apps.apple.com/br/app/imob-de-bolso/id1524592366"
                    target="_blank"
                  >
                    <img
                      alt="Imagem de um botão com logomarca da Apple para acesso a loja Apple Store"
                      src="~@/assets/icon-ios.svg"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.politiz.acp"
                    target="_blank"
                  >
                    <img
                      alt="Imagem de um botão com logomarca do Android para acesso a loja Google Play"
                      src="~@/assets/icon-android.svg"
                    />
                  </a>
                  <a
                    class="button-primary"
                    href="https://imobdebolso.com.br/"
                    target="_blank"
                  >
                    Ir para o site
                  </a>
                </div>
              </template>
            </box-two>
          </div>
        </section>

        <section class="sections_services_slider">
          <div class="container">
            <box-slider
              sliderType="secondary"
              sliderTitle="Cases AMMAX"
              :sliderArray="mockArraySlider"
            />
          </div>
        </section>
      </div>
    </template>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/LayoutBase";
import BoxTwo from "@/components/BoxTwo";
import BoxSlider from "@/components/BoxSlider";

export default {
  name: "Services",
  components: {
    LayoutBase,
    BoxTwo,
    BoxSlider,
  },
  data() {
    return {
      imageBoxOne: require("@/assets/image_service_1.png"),
      imageBoxTwo: require("@/assets/image_service_2.png"),
      imageBoxThree: require("@/assets/image_service_3.png"),
      imageBoxFour: require("@/assets/image_service_4.png"),
      imageBoxFive: require("@/assets/image_service_5.png"),
      imageBoxSix: require("@/assets/image_service_6.png"),
      imageBoxSeven: require("@/assets/image_service_8.png"),
      imageBoxEight: require("@/assets/image_service_7.png"),
      mockArraySlider: [
        {
          title: "Ecap Engenharia – Presença Digital – Segmento de Luxo",
          paragraph:
            "O desafio da AMMAX era o lançamento de empreendimentos imobiliários em Brasília para diferentes públicos. O planejamento apontou a necessidade de criar uma comunicação específica para um público classe A (os produtos Le Jardin, Self Design, My Design e High Design tinham características de exclusividade) e também para um público B, público de um lançamento no Gama/DF (Golden Green Gama). A AMMAX fez o reposicionamento do Golden Green Gama e a otimização de posicionamento em motores de busca e redes sociais para os produtos do Noroeste.",
          image: require("@/assets/image_case_1.png"),
        },
        {
          title: "Riva Incorporadora – Criação Portal Produtos",
          paragraph:
            "A missão da Riva é transformar a vida das pessoas através do mercado imobiliário. A jornada de compra de seus clientes começa com a apresentação dos seus produtos de forma interativa e lúdica, por meio do portal e telas touch. A estratégia foi gerar engajamento com essa tecnologia e interagir com o público. Um outro diferencial é o tour 360º e fotos interativas do empreendimento. A AMMAX fez o desenvolvimento do portal (incluindo criação, design, programação), setup do projeto e fornecimento das telas touch.",
          image: require("@/assets/image_case_2.png"),
          url: "https://goup.ammax.com.br/",
        },
        {
          title: "Direcional",
          paragraph:
            "Inovar na Black Friday não é das tarefas mais fáceis... ainda mais para uma força de vendas acostumada com muita criatividade no lançamento das suas campanhas. A Direcional escolheu a AMMAX para criar a estratégia da convenção específica para a data promocional. O objetivo era estreitar os laços de relacionamento com corretores de sua house e parceiros, aumentando as vendas mensais com premiações e promoções. A AMMAX fez o planejamento e a concepção do projeto, além da execução do evento.",
          image: require("@/assets/image_case_3.png"),
          video_url: "https://vimeo.com/398213128",
        },
        {
          title: "Plataforma – Imob de Bolso",
          paragraph:
            "A AMMAX criou e desenvolveu o Imob de Bolso: a melhor, mais eficiente, amigável e transparente plataforma digital do mercado imobiliário. O app, criado pela AMMAX, acompanha todos os passos da negociação desde o primeiro contato, faz a interação entre a força de vendas e o cliente, notificações. Conta ainda com possibilidade de gamificação e até um portal de indicações. O controle e gestão de cada construtora, imobiliária e corretor é transformado em números onde se tem uma visão mais ampla, realista e precisa do negócio imobiliário.",
          image: require("@/assets/image_case_4.png"),
          video_url: "https://www.youtube.com/watch?v=tZpNeiNXSy0",
          url: "https://imobdebolso.com.br/",
        },
        {
          title: "Portal Ulbrex",
          paragraph:
            "A Ulbrex é uma plataforma de investimentos voltada para o mercado imobiliário, atuando na identificação e estruturação de oportunidades de negócio e gestão dos recursos investidos. Era preciso criar um portal onde fosse possível fazer a organização dos produtos, aumentar a performance e, acima de tudo, tornar a experiência digital única para o público. Tudo isso fazendo ainda a integração com redes sociais. A AMMAX fez o planejamento, o layout, a programação, o mapeamento e a integração com as principais aplicações do mercado.",
          image: require("@/assets/image_case_5.png"),
          url: "https://deznoventa.com.br/",
        },
        {
          title: "Live Lançamento/Reserva do Horizonte",
          paragraph:
            "O isolamento social em decorrência da pandemia pelo covid-19 fez com muitos lançamentos imobiliários sofressem com a ausência de público. Na Direcional, a realidade foi outra. A AMMAX trouxe a ideia de live para apresentação de um produto em uma nova região do Distrito Federal. Com a live, foi possível reunir a força de vendas e introduzir um produto diferenciado em Samambaia/DF. A AMMAX fez o planejamento, a organização do evento, a montagem, a produção e a transmissão da live.",
          image: require("@/assets/image_case_6.png"),
          video_url: "https://www.youtube.com/watch?v=LwFA727r6M8",
        },
        {
          title: "Cine Drive IN - Direcional",
          paragraph:
            "Uma outra alternativa ao isolamento social imposto pela pandemia foi lançar o produto dentro de um drive-in. A AMMAX recriou toda a estrutura dos típicos drive-in que vemos nos filmes, respeitando todos os protocolos de segurança. A força de vendas e os parceiros da Direcional chegavam de carro e toda apresentação foi feita a céu aberto, em um telão do cinema e com ativação de palco. Claro que teve pipoca e refrigerante também.",
          image: require("@/assets/image_case_7.png"),
          video_url: "https://youtu.be/ovX7_Ggmlo0",
        },
        {
          title: "Relançamento Saint Charbel – Natal RN",
          paragraph:
            "Qual a melhor estratégia para vender um produto imobiliário na capital potiguar? Para a Ulbrex é contar com a expertise da AMMAX no planejamento e na execução de estratégias digitais. A agência criou a campanha para captação de leads e ações de engajamento da força de vendas. O resultado: produto 100% vendido em 30 dias.",
          image: require("@/assets/image_case_8.png"),
          video_url: "https://www.youtube.com/watch?v=vtr__m46Cy8",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.services {
  @media (max-width: 768px) {
    padding: 80px 0 0;
  }
  .section_services_header {
    margin: 42px auto;
    background: var(--solitude);
    border-radius: 50px;
    max-width: 1400px;
    height: 418px;

    .container {
      text-align: center;
      padding: 60px 0;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        color: var(--sherpaBlue);
        font-weight: var(--weight-regular);
        font-size: var(--size-h2);
        line-height: 56px;

        &:after {
          content: "";
          display: block;
          width: 56px;
          height: 2px;
          background: var(--sherpaBlue);
          border-radius: 5px;
          margin: 26px auto 32px;
        }
      }

      p {
        font-weight: var(--weight-regular);
        font-size: var(--size-h5);
        line-height: 30px;
        color: var(--topaz);
        max-width: 952px;
        margin: 0 auto;
      }
    }

    @media (max-width: 768px) {
      margin: 0 auto 36px;
      height: auto;

      .container {
        padding: 36px 20px;

        h2 {
          font-size: 30px;
          line-height: 26px;
        }
      }
    }
  }

  .sections_services_slider {
    margin: 42px auto 84px;
    background: var(--solitude);
    border-radius: 50px;
    max-width: 1400px;

    @media (max-width: 768px) {
      padding: 0px 20px;
    }
  }

  .sections_services {
    padding: 72px 0;
    @media (max-width: 768px) {
      padding: 36px 20px;
    }
  }

  .buttons_store {
    margin-top: 30px;
    a {
      cursor: pointer;
      display: block;
      margin-bottom: 8px;
    }
  }

  .sections_services__one,
  .sections_services__three {
    .container {
      max-width: 1040px;
    }
  }

  .sections_services__two {
    padding: 72px 0;
    background: var(--solitude);
    border-radius: 50px;
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 36px 20px;
    }
  }

  .sections_services__two,
  .sections_services__four {
    .container {
      max-width: 1140px;

      .button-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 148px;
        color: var(--arapawa);
        font-weight: var(--weight-regular);
        font-size: var(--size-base);
        line-height: 100%;
        transition: all 200ms ease-in-out;
        text-decoration: none;
        margin-right: 20px;

        &:hover,
        &:active,
        &:focus {
          transition: all 200ms ease-in-out;
          font-size: var(--size-regular);
        }
      }
    }
  }
}
</style>
