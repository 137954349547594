<template>
  <layout-base>
    <template slot="main-layout">
      <div class="contact">
        <section
          class="section_contact_header"
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-delay="500"
        >
          <div class="container">
            <h2>Vamos Conversar</h2>
            <a
              class="section_home_banner_help__button"
              href="https://wa.me/5561985100000"
              target="_blank"
            >
              <img src="~@/assets/icon_whatsapp-pink.svg" alt="whatsapp" />
            </a>
          </div>
        </section>

        <section class="section_contact_infos">
          <div class="container">
            <div class="section_contact_infos__col1">
              <h3>Brasília</h3>
              <map-box-one />

              <h3>São Paulo</h3>
              <map-box-two />
            </div>
            <div class="section_contact_infos__col2">
              <div class="section_contact_infos__block">
                <h3>Fale Conosco</h3>
                <h5>Dúvidas?</h5>
                <a href="mailto:contato@ammax.com.br">contato@ammax.com.br</a>
                <h5>Precisa de ajuda?</h5>
                <a href="mailto:contato@ammax.com.br">contato@ammax.com.br</a>
              </div>

              <div class="section_contact_infos__block">
                <h3>Escritórios</h3>
                <h5>Brasília- DF</h5>
                <p>
                  Sia Quadra 3C – Lotes 3 e 4 – Sala 206/210, Brasília – DF,
                  71200-035
                </p>
                <h5>São Paulo - SP</h5>
                <p>
                  AL IRAE, 620 SL, 128 AN 12 - Moema, São Paulo-SP, 04075-903
                </p>
              </div>

              <div class="section_contact_infos__block">
                <h3>Baixar App imob de bolso</h3>
                <div class="buttons_store">
                  <a
                    href="https://apps.apple.com/br/app/imob-de-bolso/id1524592366"
                    target="_blank"
                  >
                    <img
                      alt="Imagem de um botão com logomarca da Apple para acesso a loja Apple Store"
                      src="~@/assets/icon-ios.svg"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.politiz.acp"
                    target="_blank"
                  >
                    <img
                      alt="Imagem de um botão com logomarca do Android para acesso a loja Google Play"
                      src="~@/assets/icon-android.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/LayoutBase";
import MapBoxOne from "@/components/MapBoxOne";
import MapBoxTwo from "@/components/MapBoxTwo";

export default {
  name: "Contact",
  components: {
    LayoutBase,
    MapBoxOne,
    MapBoxTwo,
  },

  data() {
    return {
      location: [-15.786767, -47.888904],
      location2: [-47.896821, -15.785684],
    };
  },
};
</script>

<style lang="scss" scoped>
.contact {
  @media (max-width: 768px) {
    padding: 80px 0 0;
  }
  .section_contact_header {
    margin: 42px auto;
    background: var(--solitude);
    border-radius: 50px;
    max-width: 1400px;
    height: 418px;

    .container {
      text-align: center;
      padding: 60px 0;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        color: var(--sherpaBlue);
        font-weight: var(--weight-regular);
        font-size: var(--size-h2);
        line-height: 56px;

        &:after {
          content: "";
          display: block;
          width: 56px;
          height: 2px;
          background: var(--sherpaBlue);
          border-radius: 5px;
          margin: 26px auto 32px;
        }
      }

      p {
        font-weight: var(--weight-regular);
        font-size: var(--size-h5);
        line-height: 30px;
        color: var(--topaz);
        max-width: 952px;
        margin: 0 auto;
      }
    }

    @media (max-width: 768px) {
      margin: 0 auto 36px;
      height: auto;

      .container {
        padding: 36px 20px;

        h2 {
          font-size: 30px;
          line-height: 26px;
        }
      }
    }
  }

  .section_contact_infos {
    margin-top: 100px;

    .container {
      max-width: 1100px;
      width: 100%;
      justify-content: space-between;
      display: flex;
    }

    h3 {
      color: var(--sherpaBlue);
      margin-bottom: 24px;
      font-weight: var(--weight-regular);
      font-size: var(--size-h4);
      line-height: 32px;
      max-width: 180px;
    }

    h5 {
      font-weight: var(--weight-regular);
      font-size: var(--size-base);
      line-height: 28px;
      color: var(--stormGrey);
    }

    p {
      font-weight: var(--weight-regular);
      font-size: var(--size-small);
      line-height: 28px;
      margin-bottom: 16px;
      color: var(--bastille);
    }

    a {
      font-weight: var(--weight-regular);
      font-size: var(--size-base);
      line-height: 28px;
      margin-bottom: 16px;
      display: block;
      color: var(--bastille);
      text-decoration: none;
      transition: all 200ms ease-in-out;

      &:hover,
      &:focus,
      &:active {
        transition: all 200ms ease-in-out;
        color: var(--arapawa);
      }
    }

    .buttons_store {
      display: flex;

      a {
        cursor: pointer;

        img {
          max-width: 148px;
          height: auto;
        }

        &:nth-child(1) {
          margin-right: 12px;
        }
      }
    }

    .section_contact_infos__block {
      margin-bottom: 80px;
    }

    .section_contact_infos__col1 {
      max-width: 576px;
      width: 100%;
    }

    .section_contact_infos__col2 {
      max-width: 385px;
      width: 100%;
    }

    @media (max-width: 768px) {
      margin-top: 50px;

      .container {
        padding: 0 20px 0;
        flex-direction: column;
      }

      h3 {
        font-size: 20px;
        line-height: 22px;
      }

      h5 {
        font-size: 16px;
        line-height: 22px;
      }

      a,
      p {
        font-size: 16px;
      }

      .section_contact_infos__block {
        margin-bottom: 40px;
      }

      .buttons_store a img {
        max-width: 120px;
      }
    }
  }
}
</style>
