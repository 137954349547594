<template>
  <div class="layout-base">
    <header class="header">
      <div class="container">
        <figure class="header__logo">
          <router-link :to="{ name: 'Home' }" exact>
            <img alt="Logomarca Ammax" src="~@/assets/logo.svg" />
          </router-link>
        </figure>
        <nav class="header__nav" :class="{ 'is-active': activeMenu }">
          <router-link :to="{ name: 'Home' }" exact> Home </router-link>
          <a href="https://blog.ammax.com.br/"> Blog </a>
          <router-link :to="{ name: 'Services' }" exact> Serviços </router-link>
          <router-link :to="{ name: 'Contact' }" exact> Contato </router-link>
        </nav>
        <div
          class="hamburger hamburger--squeeze"
          :class="{ 'is-active': activeMenu }"
          @click="toggleMenu()"
        >
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </div>
    </header>
    <main class="main">
      <slot name="main-layout" />
    </main>
    <footer class="footer">
      <section class="footer__infos">
        <div class="container">
          <figure class="footer__infos__logo">
            <img alt="Logomarca Ammax" src="~@/assets/logo-footer.svg" />
          </figure>
          <div class="footer__infos__boxes">
            <div>
              <h2>Brasília</h2>
              <p>
                Sia Quadra 3C – Lotes 3 e 4 – Sala 206/210, Brasília – DF,
                71200-035
              </p>
              <div class="footer_phone">
                <a href="https://wa.me/5561985100000" target="_blank">
                  <img src="~@/assets/icon-whatsapp.png" alt="whatsapp" />
                </a>
                <p>+55 61 98510-0000</p>
              </div>
            </div>
            <div>
              <h2>São Paulo</h2>
              <p>AL IRAE, 620 SL, 128 AN 12 - Moema, São Paulo-SP, 04075-903</p>
              <p></p>
            </div>
            <div>
              <h2>Redes Sociais</h2>
              <ul>
                <li>
                  <a
                    href="https://pt-br.facebook.com/ammaxmarketing/"
                    target="_blank"
                    ><icon-facebook
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC3w-zF-5suQ8llYe1ysOl6A/featured"
                    target="_blank"
                    ><icon-youtube
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/ammax-marketing-imobili%C3%A1rio"
                    target="_blank"
                    ><icon-twitter
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ammax.com.br/"
                    target="_blank"
                    ><icon-instagram
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="footer__menu">
        <div class="container">
          <p>©AMMAX</p>
          <nav class="footer__menu__nav">
            <router-link :to="{ name: 'Home' }" exact> Home </router-link>
            <a href="https://blog.ammax.com.br/"> Blog </a>
            <router-link :to="{ name: 'Services' }" exact>
              Serviços
            </router-link>
            <router-link :to="{ name: 'Contact' }" exact> Contato </router-link>
          </nav>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import IconFacebook from "@/components/icons/IconFacebook";
import IconInstagram from "@/components/icons/IconInstagram";
import IconTwitter from "@/components/icons/IconTwitter";
import IconYoutube from "@/components/icons/IconYoutube";

export default {
  name: "LayoutBase",

  components: {
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconYoutube,
  },

  data() {
    return {
      activeMenu: false,
    };
  },

  methods: {
    toggleMenu() {
      this.activeMenu = !this.activeMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-base {
  height: 100%;

  .header {
    border-bottom: 1px solid var(--gainsboro);
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--white);

    .container {
      height: 72px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .header__logo {
      a {
        height: 100%;
        cursor: pointer;
        text-decoration: none;
        display: flex;
      }
    }

    .header__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 284px;
      width: 100%;

      a {
        text-decoration: none;
        color: var(--rhino);
        font-size: var(--size-header-menu);
        line-height: 24px;
        padding: 1rem 0.5rem;
        font-weight: 400;
        opacity: 0.5;
        transition: all 200ms ease-in-out;
        font-weight: var(--weight-regular);

        &:hover,
        &:focus,
        &:active,
        &.active {
          opacity: 1;
          transition: all 200ms ease-in-out;
          color: var(--hollywoodCerise);
          font-weight: 700;
        }
      }
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      width: 100%;

      .header__nav {
        position: fixed;
        height: calc(100% - 60px);
        top: 81px;
        left: -320px;
        width: 300px;
        background: var(--white);
        z-index: 9;
        box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.2);
        padding: 0 0;
        transition: all 200ms ease-out;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        a {
          display: block;
          width: 100%;
          padding: 16px 20px;
        }

        &.is-active {
          left: 0;
          transition: all 200ms ease-out;
        }
      }

      .container {
        padding: 0 20px;
        height: 80px;
      }
    }
  }

  .footer {
    background: var(--white);
    color: var(--sherpaBlue);
    border-top: solid 2px var(--grayFooter);

    section {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &.footer__infos {
        padding: 72px 0 50px;
        align-items: flex-start;

        .footer__infos__logo {
          max-width: 270px;
          width: 100%;
        }

        .footer__infos__boxes {
          display: flex;
          max-width: 950px;
          width: 100%;
          gap: 64px;

          div {
            display: flex;
            flex-direction: column;
            padding-right: 20px;
            max-width: 250px;
            width: 100%;
          }

          h2 {
            font-weight: var(--weight-semibold);
            font-size: var(--size-base);
            line-height: 23px;
            color: var(--sherpaBlue);
            margin-bottom: 16px;
          }

          .footer_phone {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
              filter: invert(100%);
            }
          }

          p {
            font-size: var(--size-small);
            margin-bottom: 8px;
            line-height: 18px;
            font-weight: var(--weight-regular);
            text-align: left;
          }

          ul {
            li {
              list-style: none;
              display: inline-block;
              a {
                margin-right: 16px;

                ::v-deep .icon-circle {
                  fill: var(--hollywoodCerise);
                }

                &:hover,
                &:active {
                  ::v-deep .icon-circle {
                    fill: var(--hollywoodCerise);
                  }

                  ::v-deep .icon-image {
                    fill: var(--white);
                  }
                }
              }
            }
          }
        }
      }

      &.footer__menu {
        padding: 50px 0;

        .container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-weight: var(--weight-semibold);
            font-size: var(--size-regular);
            line-height: 20px;
          }

          .footer__menu__nav {
            a {
              font-weight: var(--weight-semibold);
              font-size: 16px;
              line-height: 20px;
              color: var(--sherpaBlue);
              text-decoration: none;
              margin: 0 30px;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0px 20px;

      section.footer__infos {
        padding: 36px 0;
        .container {
          flex-direction: column;

          .footer__infos__logo {
            margin-bottom: 24px;
          }

          .footer__infos__boxes {
            flex-direction: column;

            div {
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      section.footer__menu {
        padding: 16px 0;
        .container {
          .footer__menu__nav {
            display: none;
          }
        }
      }
    }
  }
}
</style>
