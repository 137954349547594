<template>
  <div class="maps">
    <MglMap
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      @load="onMapLoaded"
      :center="center"
      :zoom="zoom"
    >
      <MglMarker :coordinates="center" color="#30B0C1" />
    </MglMap>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";

export default {
  name: "MapBoxTwo",

  components: {
    MglMap,
    MglMarker,
  },

  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiZmlsaXBlbWVuZXplcyIsImEiOiJjajk2enpjNXAwMjQ4MzNvN21venV4NXl1In0.9pFTCli8pcYYz3c-RA5-3w",
      mapStyle: "mapbox://styles/mapbox/dark-v10",
      center: [-46.66151681773235, -23.60399649188164],
      zoom: 15,
    };
  },

  created() {
    this.mapbox = Mapbox;
  },

  methods: {
    async onMapLoaded(event) {
      const asyncActions = event.component.actions;

      await asyncActions.flyTo({
        center: this.center,
        zoom: 9,
        speed: 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.maps {
  ::v-deep.mgl-map-wrapper {
    position: relative;
    height: 268px;
    width: 576px;
    border-radius: 20px;
    margin-bottom: 32px;

    .mapboxgl-canvas {
      border-radius: 20px;
      height: 268px;
      width: 576px;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 32px;

      .mapboxgl-canvas {
        width: 100%;
      }
    }
  }
}
</style>
