<template>
  <section class="box_slider" :class="'box_slider_' + sliderType">
    <h2>{{ sliderTitle }}</h2>
    <div class="box_slider__content">
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in sliderArray" :key="index">
          <div class="box_slider__content__box">
            <div class="box_slider__content__box__col1">
              <h3>{{ item.title }}</h3>
              <p>{{ item.paragraph }}</p>
              <div class="box_slider__content__navigation__count">
                <span>
                  <span
                    >{{ index + 1 < 10 ? `0${index + 1}` : `${index + 1}` }}/
                  </span>
                  <span>{{
                    sliderArray.length < 10
                      ? `0${sliderArray.length}`
                      : `${sliderArray.length}`
                  }}</span>
                </span>
              </div>
            </div>
            <div class="box_slider__content__box__col2">
              <img
                class="box_slider__content__box__col2__image"
                alt=""
                :src="item.image"
              />
              <div class="box_slider__buttons">
                <a
                  v-if="item.url"
                  :href="item.url"
                  target="_blank"
                  class="button-primary"
                >
                  Ir para o site
                </a>
                <a
                  v-if="item.video_url"
                  :href="item.video_url"
                  target="_blank"
                  class="button-play"
                >
                  <img
                    src="~@/assets/icon-play.svg"
                    alt="Imagem de apoio do botão ver vídeo"
                  />
                  Ver vídeo
                </a>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "BoxSlider",

  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        effect: "fade",
        speed: 400,
        loop: false,
        slidesPerView: 1,
        simulateTouch: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  props: {
    sliderType: {
      type: String,
      required: true,
    },
    sliderTitle: {
      type: String,
      required: true,
    },
    sliderArray: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.box_slider {
  padding: 108px 0;
  position: relative;

  h2 {
    position: relative;
    top: 42px;
    z-index: 9;
    font-weight: var(--weight-regular);
    font-size: 21px;
    line-height: 22px;
    color: #486284;
  }

  .box_slider__content__box {
    .box_slider__content__box__col1 {
      padding: 60px 0 0;

      h3 {
        margin-bottom: 8px;
        font-size: 44px;
        line-height: 56px;
        color: var(--sherpaBlue);
        font-weight: var(--weight-regular);
        text-transform: uppercase;
        max-width: 600px;
      }

      p {
        max-width: 570px;
        color: var(--topaz);
        line-height: 30px;
        height: 280px;
        overflow: hidden;
        margin-bottom: 70px;
        font-size: var(--size-base);
      }

      .box_slider__content__navigation__count {
        position: absolute;
        top: 525px;
        padding-left: 130px;

        span {
          background-color: var(--white);
          color: var(--topaz);
          font-size: var(--size-regular);
          line-height: 30px;
          font-weight: var(--weight-semibold);
          display: flex;
          span {
            &:nth-child(1) {
              font-size: 20px;
              font-weight: var(--weight-extrabold);
            }
          }
        }
      }
    }

    .box_slider__content__box__col2 {
      max-width: 550px;
      display: flex;
      align-items: center;
      height: 452px;
      position: relative;
      justify-content: center;

      img {
        max-width: 540px;
      }

      .box_slider__buttons {
        width: 100%;
        height: 50px;
        background-color: var(--white);
        position: absolute;
        top: calc(100% + 60px);
        display: flex;
        justify-content: right;
      }
    }
  }

  .button-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 164px;
    color: var(--hollywoodCerise);
    font-weight: var(--weight-regular);
    font-size: var(--size-base);
    line-height: 100%;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    margin-right: 20px;
    z-index: 1000;

    &:hover,
    &:active,
    &:focus {
      transition: all 200ms ease-in-out;
      font-size: var(--size-regular);
    }
  }

  .button-play {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 164px;
    border: solid 1px var(--hollywoodCerise);
    border-radius: 20px;
    color: var(--white);
    background: var(--hollywoodCerise);
    font-weight: var(--weight-regular);
    font-size: var(--size-base);
    line-height: 100%;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    margin-right: 20px;
    z-index: 1000;

    img {
      margin-right: 8px;
    }

    &:hover,
    &:active,
    &:focus {
      transition: all 200ms ease-in-out;
    }
  }

  ::v-deep.swiper-slide {
    max-width: 1200px;
    background-color: var(--white);
  }

  ::v-deep .box_slider__content {
    .box_slider__content__box {
      display: flex;
      justify-content: space-between;
      background: var(--white);
    }

    .swiper-button-prev {
      background: url("~@/assets/icon-arrow-left.svg") center center no-repeat;
      width: 53px;
      height: 53px;
      color: transparent;
      left: 0;
      top: 538px;
    }
    .swiper-button-next {
      background: url("~@/assets/icon-arrow-right.svg") center center no-repeat;
      width: 53px;
      height: 53px;
      color: transparent;
      left: 65px;
      right: inherit;
      top: 538px;
    }
  }

  &.box_slider_primary {
  }

  &.box_slider_secondary {
    ::v-deep.swiper-slide {
      background-color: var(--solitude);
    }

    h2 {
      position: relative;
      top: 42px;
      z-index: 9;
      font-weight: var(--weight-regular);
      font-size: 21px;
      line-height: 22px;
      color: #486284;
    }

    .box_slider__content__box {
      background-color: var(--solitude);
      .box_slider__content__box__col1 {
        h3 {
          font-size: 44px;
          line-height: 56px;
          color: var(--sherpaBlue);
          font-weight: var(--weight-regular);
          text-transform: uppercase;
        }

        .box_slider__content__navigation__count {
          background: var(--solitude);

          span {
            background: var(--solitude);
          }
        }
      }

      .box_slider__content__box__col2 {
        .box_slider__buttons {
          background-color: var(--solitude);
        }
      }

      @media (max-width: 768px) {
        .box_slider__content__box__col2 {
          .box_slider__buttons {
            background-color: var(--solitude);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 54px 0;

    h2 {
      font-size: 36px;
      top: 0;
    }

    ::v-deep .box_slider__content {
      .box_slider__content__box {
        flex-direction: column;
        margin-top: 60px;

        .box_slider__content__box__col1 {
          order: 1;
          padding: 70px 0 0;
          width: 100%;

          h3 {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 26px;
          }

          p {
            height: 500px;
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 0;
          }

          .box_slider__content__navigation__count {
            display: none;
          }
        }

        .box_slider__content__box__col2 {
          order: 0;
          width: 100%;
          height: auto;
          display: block;

          .box_slider__buttons {
            width: 100%;
            position: relative;
            display: block;
            align-items: center;

            a {
              width: 100%;
              margin: 8px 0px;
            }
          }
        }
      }

      .swiper-button-prev {
        left: 0;
        top: 40px;
        width: 32px;
        height: 32px;
        background-size: cover;
      }
      .swiper-button-next {
        width: 32px;
        height: 32px;
        background-size: cover;
        left: 45px;
        top: 40px;
      }
    }

    .box_slider__content__box {
      .box_slider__content__box__col2 {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
