<template>
  <section class="box_two" :class="'box_two_' + themeType">
    <div class="box_two__image">
      <img :src="image" alt="" />
    </div>
    <div class="box_two__infos">
      <img
        class="box_two__infos__icon"
        v-if="iconImage"
        :src="iconImage"
        alt=""
      />
      <h2>{{ title }}</h2>
      <p>{{ textOne }}</p>
      <p v-if="textTwo">{{ textTwo }}</p>
      <slot name="infos-slot" />
    </div>
  </section>
</template>

<script>
export default {
  name: "BoxTwo",
  props: {
    themeType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    textOne: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    iconImage: {
      type: String,
      required: false,
    },
    textTwo: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.box_two {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.box_two_default {
    .box_two__image {
      order: 0;
    }
    .box_two__infos {
      order: 1;
    }
  }

  &.box_two_inverted {
    .box_two__image {
      order: 1;
    }
    .box_two__infos {
      order: 0;
    }

    @media (max-width: 768px) {
      .box_two__image {
        order: 0;
      }
      .box_two__infos {
        order: 1;
      }
    }
  }

  .box_two__image {
    max-width: 570px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px;

    img {
      max-width: 550px;
      height: auto;
    }
  }

  .box_two__infos {
    max-width: 570px;

    img.box_two__infos__icon {
      margin-bottom: 26px;
    }

    h2 {
      color: var(--sherpaBlue);
      font-weight: var(--weight-regular);
      font-size: var(--size-box-two-h2);
      line-height: 66px;
      margin-bottom: 16px;
    }

    p {
      color: var(--topaz);
      font-weight: var(--weight-regular);
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .box_two__image {
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 20px;

      img {
        max-width: 310px;
        height: auto;
      }
    }

    .box_two__infos {
      h2 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 12px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
