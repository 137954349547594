<template>
  <layout-base>
    <template slot="main-layout">
      <div class="home">
        <section class="home__hero">
          <div class="home__hero__body">
            <div class="container">
              <div data-aos="slide-right">
                <h2 class="title">
                  Soluções de ponta a ponta para o mercado imobiliário
                </h2>
                <p class="subtitle">
                  Somos experts em planejamento, execução, ativações, soluções
                  ponta a ponta e experiências que facilitem a jornada de compra
                  e venda de imóveis.
                </p>
                <a href="#firstSection">
                  <img
                    alt="Imagem de uma seta apontando para baixo"
                    src="~@/assets/icon-arrow-down.svg"
                  />
                </a>
              </div>
              <div data-aos="slide-left">
                <figure>
                  <img
                    alt="Imagem banner principal AMMAX"
                    src="~@/assets/hero_image.svg"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section id="firstSection" class="section-home-one">
          <div class="container">
            <box-two
              themeType="default"
              title="Nosso propósito nos trouxe até aqui"
              :image="imageBoxOne"
              textOne="Em 2006, a AMMAX iniciou sua trajetória como uma agência de promoção ao lançar um dos mais grandiosos eventos imobiliários da Capital Federal, visando a venda de imóveis. Esse foi apenas o primeiro passo."
              textTwo="Ao longo do tempo, evoluímos para uma empresa completa, gerenciando todos os aspectos da comunicação de forma integrada - desde o briefing e criação até a experiência do cliente e a venda do imóvel. Estamos com nossos clientes em todas as etapas do processo."
            />
          </div>
        </section>

        <section class="section-home-two">
          <div class="container">
            <box-two
              themeType="inverted"
              title="Mais do que grandes clientes, temos grandes parceiros"
              :image="imageBoxTwo"
              textOne="A AMMAX já realizou centenas de lançamentos imobiliários com algumas das maiores e mais conceituadas empresas de construção e imobiliárias do Brasil. Contribuímos para a venda de imóveis para os mais diversos segmentos e públicos. Também aprendemos muito."
              textTwo="A proximidade com nossos clientes, a troca de experiências e a relação de “só é bom quando todos ganham” nos tornou uma agência mais completa, pronta para assumir novas parcerias e grandes desafios."
            />
          </div>
        </section>

        <section id="firstSection" class="section-home-one">
          <div class="container">
            <box-two
              themeType="default"
              title="Projetos especiais? É com a gente mesmo"
              :image="imageBoxThree"
              textOne="Fazer diferente para fazer a diferença. A AMMAX usa e abusa da criatividade e da tecnologia em projetos especiais. Temos a expertise e os parceiros certos para a criação de apresentações customizadas, ambientes imersivos, locação de espaços e equipamentos para experiências únicas. Tem uma ideia audaciosa e quer colocar em prática? Que bom, você encontrou a agência certa."
              textTwo=""
            />
          </div>
        </section>

        <section class="section_home_services">
          <div class="container">
            <h2>Como podemos contribuir para o sucesso do seu negócio</h2>
            <p>
              Aqui você poderá entender um pouco mais sobre todos os serviços
              que a AMMAX pode prestar por você. Começando por Planejamento,
              entendendo o que é Distribuição e por fim, como podemos realizar
              suas vendas utilizando toda a nossa estratégia e inteligência de
              mercado.
            </p>
            <div class="section_home_services__boxes">
              <div class="section_home_services__boxes__box">
                <img
                  src="~@/assets/icon_service_one.svg"
                  alt="Imagem com icone relacionado ao box planejamento"
                />
                <h3>Planejamento</h3>
                <p>
                  Toda estratégia bem-sucedida começa com planejamento. Só se
                  chega aonde se quer chegar quando se sabe como chegar lá.
                </p>
              </div>
              <div class="section_home_services__boxes__box">
                <img
                  src="~@/assets/icon_service_two.svg"
                  alt="Imagem com icone relacionado ao box planejamento"
                />
                <h3>Canais</h3>
                <p>
                  Qual a melhor forma de impactar o seu público-alvo? Fazemos a
                  operação e distribuição de todos as mídias de forma integrada.
                </p>
              </div>
              <div class="section_home_services__boxes__box">
                <img
                  src="~@/assets/icon_service_three.svg"
                  alt="Imagem com icone relacionado ao box planejamento"
                />
                <h3>Inovação</h3>
                <p>
                  O mundo muda a cada hora. A forma de vender imóveis também.
                  Oferecemos ideias e soluções inovadoras para nossos clientes.
                </p>
              </div>
              <div class="section_home_services__boxes__box">
                <img
                  src="~@/assets/icon_service_four.svg"
                  alt="Imagem com icone relacionado ao box planejamento"
                />
                <h3>Imob de Bolso</h3>
                <p>
                  Conheça nossa ferramenta facilitadora e com melhor
                  custo-benefício para a divulgação e negociação de imóveis.
                </p>
              </div>
            </div>
            <router-link
              :to="{ name: 'Services' }"
              exact
              class="section_home_services__button"
            >
              Saiba mais
            </router-link>
          </div>
        </section>

        <section class="section_home_slider">
          <div class="container">
            <box-slider
              sliderType="primary"
              sliderTitle="Cases AMMAX"
              :sliderArray="mockArraySlider"
            />
          </div>
        </section>

        <section class="section_home_banner_help">
          <div class="container">
            <div class="section_home_banner_help__infos">
              <h2>Como podemos te ajudar?</h2>
              <p>Entre em contato com a AMMAX e vamos tomar um café.</p>
            </div>
            <a
              class="section_home_banner_help__button"
              href="https://wa.me/5561985100000"
              target="_blank"
            >
              <img src="~@/assets/icon_whatsapp.svg" alt="whatsapp" />
              Entrar em contato
            </a>
          </div>
        </section>
      </div>
    </template>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/LayoutBase";
import BoxTwo from "@/components/BoxTwo";
import BoxSlider from "@/components/BoxSlider";

export default {
  name: "Home",
  components: {
    LayoutBase,
    BoxTwo,
    BoxSlider,
  },
  data() {
    return {
      imageBoxOne: require("@/assets/image_home_01.svg"),
      imageIconBoxOne: require("@/assets/icon-about.svg"),
      imageBoxTwo: require("@/assets/image_home_02.svg"),
      imageBoxThree: require("@/assets/image_home_03.svg"),
      mockArraySlider: [
        {
          title: "Ecap Engenharia – Presença Digital – Segmento de Luxo",
          paragraph:
            "O desafio da AMMAX era o lançamento de empreendimentos imobiliários em Brasília para diferentes públicos. O planejamento apontou a necessidade de criar uma comunicação específica para um público classe A (os produtos Le Jardin, Self Design, My Design e High Design tinham características de exclusividade) e também para um público B, público de um lançamento no Gama/DF (Golden Green Gama). A AMMAX fez o reposicionamento do Golden Green Gama e a otimização de posicionamento em motores de busca e redes sociais para os produtos do Noroeste.",
          image: require("@/assets/image_case_1.png"),
        },
        {
          title: "Riva Incorporadora – Criação Portal Produtos",
          paragraph:
            "A missão da Riva é transformar a vida das pessoas através do mercado imobiliário. A jornada de compra de seus clientes começa com a apresentação dos seus produtos de forma interativa e lúdica, por meio do portal e telas touch. A estratégia foi gerar engajamento com essa tecnologia e interagir com o público. Um outro diferencial é o tour 360º e fotos interativas do empreendimento. A AMMAX fez o desenvolvimento do portal (incluindo criação, design, programação), setup do projeto e fornecimento das telas touch.",
          image: require("@/assets/image_case_2.png"),
          url: "https://goup.ammax.com.br/",
        },
        {
          title: "Direcional",
          paragraph:
            "Inovar na Black Friday não é das tarefas mais fáceis... ainda mais para uma força de vendas acostumada com muita criatividade no lançamento das suas campanhas. A Direcional escolheu a AMMAX para criar a estratégia da convenção específica para a data promocional. O objetivo era estreitar os laços de relacionamento com corretores de sua house e parceiros, aumentando as vendas mensais com premiações e promoções. A AMMAX fez o planejamento e a concepção do projeto, além da execução do evento.",
          image: require("@/assets/image_case_3.png"),
          video_url: "https://vimeo.com/398213128",
        },
        {
          title: "Plataforma – Imob de Bolso",
          paragraph:
            "A AMMAX criou e desenvolveu o Imob de Bolso: a melhor, mais eficiente, amigável e transparente plataforma digital do mercado imobiliário. O app, criado pela AMMAX, acompanha todos os passos da negociação desde o primeiro contato, faz a interação entre a força de vendas e o cliente, notificações. Conta ainda com possibilidade de gamificação e até um portal de indicações. O controle e gestão de cada construtora, imobiliária e corretor é transformado em números onde se tem uma visão mais ampla, realista e precisa do negócio imobiliário.",
          image: require("@/assets/image_case_4.png"),
          video_url: "https://www.youtube.com/watch?v=tZpNeiNXSy0",
          url: "https://imobdebolso.com/",
        },
        {
          title: "Portal Ulbrex",
          paragraph:
            "A Ulbrex é uma plataforma de investimentos voltada para o mercado imobiliário, atuando na identificação e estruturação de oportunidades de negócio e gestão dos recursos investidos. Era preciso criar um portal onde fosse possível fazer a organização dos produtos, aumentar a performance e, acima de tudo, tornar a experiência digital única para o público. Tudo isso fazendo ainda a integração com redes sociais. A AMMAX fez o planejamento, o layout, a programação, o mapeamento e a integração com as principais aplicações do mercado.",
          image: require("@/assets/image_case_5.png"),
          url: "https://deznoventa.com.br/",
        },
        {
          title: "Lançamento/Reserva do Horizonte",
          paragraph:
            "O isolamento social em decorrência da pandemia pelo covid-19 fez com muitos lançamentos imobiliários sofressem com a ausência de público. Na Direcional, a realidade foi outra. A AMMAX trouxe a ideia de live para apresentação de um produto em uma nova região do Distrito Federal. Com a live, foi possível reunir a força de vendas e introduzir um produto diferenciado em Samambaia/DF. A AMMAX fez o planejamento, a organização do evento, a montagem, a produção e a transmissão da live.",
          image: require("@/assets/image_case_6.png"),
          video_url: "https://www.youtube.com/watch?v=LwFA727r6M8",
        },
        {
          title: "Cine Drive IN - Direcional",
          paragraph:
            "Uma outra alternativa ao isolamento social imposto pela pandemia foi lançar o produto dentro de um drive-in. A AMMAX recriou toda a estrutura dos típicos drive-in que vemos nos filmes, respeitando todos os protocolos de segurança. A força de vendas e os parceiros da Direcional chegavam de carro e toda apresentação foi feita a céu aberto, em um telão do cinema e com ativação de palco. Claro que teve pipoca e refrigerante também.",
          image: require("@/assets/image_case_7.png"),
          video_url: "https://youtu.be/ovX7_Ggmlo0",
        },
        {
          title: "Relançamento Saint Charbel – Natal RN",
          paragraph:
            "Qual a melhor estratégia para vender um produto imobiliário na capital potiguar? Para a Ulbrex é contar com a expertise da AMMAX no planejamento e na execução de estratégias digitais. A agência criou a campanha para captação de leads e ações de engajamento da força de vendas. O resultado: produto 100% vendido em 30 dias.",
          image: require("@/assets/image_case_8.png"),
          video_url: "https://www.youtube.com/watch?v=vtr__m46Cy8",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  @media (max-width: 768px) {
    padding: 80px 0 0;
  }

  .home__hero {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    .home__hero__body {
      padding: 5rem 6rem;

      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div:nth-child(1) {
          max-width: 610px;
          width: 100%;
        }

        div:nth-child(2) {
          max-width: 570px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      figure {
        img {
          max-width: 570px;
          height: auto;
          display: block;
        }
      }

      h2 {
        color: var(--sherpaBlue);
        font-size: var(--size-big-hero);
        line-height: 66px;
        font-weight: var(--weight-bold);
        margin-bottom: 16px;
        max-width: 610px;
      }

      p {
        font-size: var(--size-text-hero);
        line-height: 32px;
        color: var(--topaz);
        max-width: 600px;
        margin-bottom: 56px;
      }
    }

    @media (max-width: 768px) {
      .home__hero__body {
        padding: 2rem 1rem;

        .container {
          div:nth-child(2) {
            display: none;
          }
        }

        h2 {
          font-size: 46px;
          line-height: 56px;
          max-width: 300px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--topaz);
          max-width: 300px;
          margin-bottom: 26px;
        }
      }
    }
  }

  .section-home-one {
    padding: 72px 0;

    @media (max-width: 768px) {
      padding: 36px 20px;
    }
  }

  .section_home_slider {
    @media (max-width: 768px) {
      padding: 0px 20px;
    }
  }
  .section-home-two {
    background: var(--solitude);
    padding: 72px 0;
    max-width: 1440px;
    margin: 0 auto;
    border-radius: 50px;

    @media (max-width: 768px) {
      padding: 36px 20px;
    }
  }

  .section_home_services {
    margin: 0 auto 352px;
    background: var(--solitude);
    border-radius: 50px;
    max-width: 1400px;

    .container {
      text-align: center;
      height: 580px;

      h2 {
        color: var(--sherpaBlue);
        font-weight: var(--weight-regular);
        font-size: 50px;
        line-height: 66px;
        padding-top: 102px;
        max-width: 890px;
        margin: 0 auto 40px;
      }

      p {
        font-weight: var(--weight-regular);
        font-size: 20px;
        line-height: 32px;
        color: var(--topaz);
        max-width: 952px;
        margin: 0 auto 60px;
      }

      .section_home_services__button {
        max-width: 193px;
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--hollywoodCerise);
        border-radius: 20px;
        color: var(--white);
        text-decoration: none;
        transition: all 200ms ease-in-out;
        margin: 60px auto 0;

        &:hover,
        &:active,
        &:focus {
          background: var(--hollywoodCerise);
          color: var(--white);
          transition: all 200ms ease-in-out;
        }
      }

      .section_home_services__boxes {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .section_home_services__boxes__box {
          background: var(--white);
          box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
          border-radius: 20px;
          max-width: 283px;
          width: 100%;
          height: 354px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          padding: 18px 30px;
          box-sizing: border-box;
          transition: transform 300ms ease;

          img {
            margin: 24px 0;
          }

          h3 {
            font-size: var(--size-h4);
            line-height: 56px;
            color: var(--sherpaBlue);
            font-weight: var(--weight-regular);
            margin-bottom: 8px;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              height: 2px;
              bottom: 0;
              left: 0;
              width: calc(100% + 30px);
              background: var(--hollywoodCerise);
              transform: scaleX(0);
              transform-origin: left;
              transition: transform 300ms ease;
            }
          }

          p {
            text-align: left;
            margin: 0 0;
            font-weight: var(--weight-light);
            font-size: var(--size-regular);
            line-height: 28px;
          }

          &:hover,
          &:active,
          &:focus {
            transform: scale(1.06);
            box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.6);
            transition: transform 300ms ease;

            h3 {
              &:after {
                transform: scaleX(1);
                transition: transform 300ms ease;
              }
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 36px 20px 72px;
      margin: 0 auto 36px;
      .container {
        height: auto;

        h2 {
          padding-top: 36px;
        }

        .section_home_services__boxes {
          flex-direction: column;

          .section_home_services__boxes__box {
            max-width: 320px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .section_home_banner_help {
    margin: 40px 0 140px;

    .container {
      background: var(--sherpaBlue);
      border-radius: 20px;
      height: 240px;
      padding: 0 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--white);

      .section_home_banner_help__button {
        img {
          margin-right: 8px;
        }
      }

      h2 {
        font-weight: var(--weight-regular);
        font-size: 40px;
        line-height: 66px;
        margin-bottom: 12px;
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
      }

      a {
        max-width: 271px;
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        justify-content: center;
        background: var(--hollywoodCerise);
        color: var(--white);
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        text-decoration: none;
        transition: all 200ms ease-in-out;

        img {
          filter: invert(100%);
        }

        &:hover,
        &:active,
        &:focus {
          background: var(--hollywoodCerise);
          color: var(--white);
          transition: all 200ms ease-in-out;

          img {
            filter: invert(100%);
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 36px 20px;
      margin: 20px 0;

      .container {
        height: auto;
        padding: 36px;
        flex-direction: column;

        h2 {
          font-size: 36px;
          line-height: 46px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
        }

        a {
          max-width: 300px;
          margin-top: 24px;
        }
      }
    }
  }
}
</style>
